.CloseIcon {
    margin-top: -10px;
    margin-left: 20px;
    position: absolute;
}

.content-space-between {
    justify-content: space-between;
}

.btn-default {
    background-color: #f1f1f1 !important;
}

.h-calc1 {
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

.h-calc2 {
    height: calc(100vh - 400px);
    overflow-y: scroll;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.bg-ash {
    background-color: #f1f1f1;
}

.center-blocks {
    align-items: center;
    justify-content: center;
}

.bg-theme {
    background-color: #AF1F25;
    color: #ffffff;
}

.flex-end {
    align-items: center;
    justify-content: end;
}
.hoverMe{
    pointer-events: none;
    transition: all .2 cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.hoverMe:hover{
    background-color: rgba(0, 0, 0, 0.03);
}

.chaticon{
    font-size:1.7rem;
    position:absolute;
    right:0.9rem;
    color: rgba(0, 0, 0, 0.3);
    top: 50%;
    transform: translateY(-50%);
    transition: .3s ease-in-out;
    pointer-events: auto;
}
.chaticon:hover {
    color: #F94A78;
    /* background-color: rgba(0, 0, 0, 0.03); */
}