@import "rsuite/dist/rsuite.css";
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Courier+Prime:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Courier+Prime:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rethink+Sans:wght@400;500;600;700;800&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

#page-local-report_completion_overview-index th.header {
  writing-mode: vertical-lr;
}

.img-container img{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
}


.sidebar {
  overflow: hidden;
    width: 16rem !important;
}

.IconFormData {
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidebar .accordion-body {
  padding-left: 2px;
  padding-right: 2px;
}

.bg-gradient-architecture {
  background : #073A5D;
}

.sidebar .sidebar-brand{
  width: auto;
  height: auto;
}

.logo {
  padding: 10px;
  width: 90px;
  height: 90px;
}

.text-right {
  text-align: right;
}

.td-none {
  text-decoration: none;
}

.pr-170px {
  padding-right: 170px !important;
}

.mr-15 { 
  margin-right: 15px;
}

.p-cursor {
  cursor: pointer !important;
}

.modal.show {
  display: block;
}

.w-100pc {
  width: 100%;
}

.fs-10px {
  font-size: 10px;
}

.fs-12px {
  font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 20px;
}

a:hover, a:active, a:visited, a:focus {
  text-decoration: none;
}

.indent-18px {
  text-indent: 18px;
}

#accordionSidebar a {
  color: #F94A78  ;
  text-indent: 10px;
  padding: 5px;
  font-size: 13px;
}

#accordionSidebar a:hover {
  background: rgba(242, 243, 246, 1);
  border-radius: 10px;
  font-size: 13px;
}

#accordionSidebar a:active, #accordionSidebar a:focus {
  background: #073b5d13;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
}

.accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.menuIcon {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 10px;
}

.fg-theme {
  color: #F94A78;
  font-weight: 600;
}

.fg-red {
  color: #073A5D;
}


.fg-green {
  color: #1FAF24;
}

.btn-primary, .nav-pills .nav-link.active, .nav-pills .nav-link, .nav-pills .show>.nav-link {
  background-color: #ffffff;
  border-color: #F94A78;
  outline: #F94A78;
  color: #000;
}

.btn-primary:hover, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:hover {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn-primary:active, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:active {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn-primary:focus, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:focus {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn .fas {
  color: inherit;
}

.form-select:focus, .form-select:active, .form-control:focus, .form-control:active {
  border-color: #b5b5b7;
  outline: 0;
  box-shadow: none;
}


.ml-15px {
  margin-left: 15px;
}
.ml-15 {
  margin-left: 15px;
}

.sweet-alert .confirm {
  background-color: #F94A78 !important;
}

.sweet-alert .cancel {
  background-color: #000000 !important;
}

.sweet-alert p {
  font-size: 25px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.err-msg {
  color: red;
}

.step-container {
  display: none;
}

.step-container.active {
  display: block;
}

.navbar .nav-item a.nav-link {
  color: #ffffff !important;
}

.navbar .nav-item a.nav-link span {
  color: #ffffff !important;
}

.navbar .nav-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navbar .nav-item a.nav-link:visited, 
.navbar .nav-item a.nav-link:focus, 
.navbar .nav-item a.nav-link:active, 
.navbar .nav-item a.nav-link:hover {
  background-color: transparent !important;
}

.navbar .nav-item a.nav-link span:visited, 
.navbar .nav-item a.nav-link span:focus, 
.navbar .nav-item a.nav-link span:active, 
.navbar .nav-item a.nav-link span:hover {
  background-color: transparent !important;
}

.mr-2 {
  margin-right: 10px;
}

.form-group {
  margin-top: 5px;
  margin-bottom: 5px;
}

.react-datepicker-wrapper {
  display: block;
  /* padding: 0; */
  /* border: 0; */
  width: 100%;
}

.react-ios-time-picker-input  {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.nav-item.dropdown .fas{
  color: #F94A78;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.simple .card-header {
  background-color: transparent;
}

.simple .card-footer {
  background-color: transparent;
}

.modal-body.simple {
  padding: 0;
}

.text-blue {
  color: #4e73df;
}


.brandLogoServer {
  width: 100px;
  height: 100px;
}

.small {
  font-size: 50%;
}

.float-right {
  float: right;
}

tr.active td {
  background-color: rgba(0, 255, 0, 0.1);
}

.providerDetails li {
  list-style: none;
  padding: 5px 10px;
  border: 1px solid #F94A78;
  border-radius: 5px;
  margin-top: 5px;
}

.providerDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 20px;
  padding: 0;
}

.locationDetails li {
  list-style: none;
  padding: 5px 10px;
  border: 1px solid #F94A78;
  border-radius: 5px;
  margin-top: 5px;
}

.locationDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 20px;
  padding: 0;
}

.form-group .form-label {
  width: 100%;
}

.form-group .rmdp-container  {
  width: 100%;
}

.form-group .rmdp-container .rmdp-input {
  width: 100%;
  padding: 5px 15px;
  height: auto;
}

.img-icon-activityList {
  width: 30px;
  height: 30px;
  /* margin-left: -30px;
  margin-top: -30px; */
  /* filter: blur(2px); */
}

.pallet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
  cursor: pointer;
}

.text-purple {
  color: #7a1faf;
}

.text-orange {
  color: #ffa200;
}

.text-theme {
  color: #F94A78;
}

.mt-4m {
  margin-top: -20px;
}

.ml-5m {
  margin-left: -25px;
}


.p-absolute {
  position: absolute;

}

.radius50 {
  border-radius: 50%;
}

.ml-2 {
  margin-left: 10px;
}


.redPallet {
  background-color: #ff0000;
}

.bluePallet {
  background-color: #0004ff;
}

.greenPallet {
  background-color: #3cff00;
}

.yellowPallet {
  background-color: #fff700;
}

.orangePallet {
  background-color: #ffaa00;
}

.skyPallet {
  background-color: #00fffb;
}

.limePallet {
  background-color: #00ff8c;
}

.purplePallet {
  background-color: #c800ff;
}

.pinkPallet {
  background-color: #ff46cb;
}

.blackPallet {
  background-color: #000000;
}

.whitePallet {
  background-color: #ffffff;
}

.ImagePallet img {
  /* width: 49.9%; */
  height: 120px;
  border-radius: 10px;
}
.ImagePallet {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ImagePallet:hover {
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}



/* Elearning */


.messageBox li {
  list-style: none;
}

.messageBox li.sender .SingleMessage {
  text-align: right;
  /* align-items: flex-start; */
  /* justify-content: right; */
  margin-right: 0;
  /* float: right; */
}

.messageBox li.receiver .SingleMessage {
  text-align: left;
  /* align-items: flex-end; */
  /* justify-content: left; */
  margin-left: 0;
}

.messageBox li .MSG {
  width: auto;
}

.messageBox li.sender .SingleMessage .MSG {
  float: right;
}

.messageBox li.receiver .SingleMessage .MSG {
  float: left;
}

.messageBox li .SingleMessage .MSG {
  position: relative;
}

.messageBox li.receiver .SingleMessage .MSG:before {
  position: absolute;
  margin-left: -15px;
  margin-top: 8px;
  border: 8px solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 12px;
  width: 0;
  pointer-events: none;
}

.messageBox li.sender .SingleMessage .MSG:before {
  position: absolute;
  margin-right: -15px;
  margin-top: 8px;
  border: 8px solid transparent;
  border-left-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}

.messageBox li .SingleMessage {
  width: 60%;
}

.box-image {
  height: 26px;
  width: auto;
}

.ml-5 {
  margin-left: 25px;
}

.h-calc2 {
  height: calc(100vh - 220px);
  overflow-y: scroll;
}

.rad-50pc {
  border-radius: 50%;
}

.img-circle {
  height: 50px;
}

.f-direction-column {
  flex-direction: column;
  margin: auto;
}

.centered-box {
  margin: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.f-Roboto-Condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

.f-courior-prime {
  font-family:'Courier Prime', Courier, monospace;
}

.f-poppins {
  font-family:'Poppins', sans-serif;
}

.f-roboto {
  font-family: 'Roboto', sans-serif;
}

.f-Oswald {
  font-family: 'Oswald', sans-serif;
}

.f-Rethink-Sans {
  font-family: 'Rethink Sans', sans-serif;
}

.fg-cert-theme {
  color: #090E44;
}

.mr-1 {
  margin-right: 5px;
}

.serial-number-text {
  margin-top: -50px;
  margin-left: 120px;
  font-family: 'Poppins';
  color: #fff;
  font-size: 16px;
}
.certificate-date-text {
  margin-top: -71px;
  margin-left: 190px;
  font-family: 'Poppins';
  color: #fff;
  font-size: 16px;
}


.fs-16px {
  font-size: 16px;
}


.fs-20px {
  font-size: 20px;
}


.fs-12px {
  font-size: 12px;
}

.fs-40px {
  font-size: 40px;
}

.fs-30px {
  font-size: 30px;
}

.fs-25px {
  font-size: 25px;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

.h-400px {
  height: 800px;
}

.t-border3 {
  border-top: 3px solid #343434;
}

.b-border3 {
  border-bottom: 3px solid #343434;
}

.certificate {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: url('../src/assets/certificate.png') no-repeat;
  background-size: 100% 100%, cover, cover;
}

.certificate_new {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: url('../src/assets/certificate1.png') no-repeat;
  background-size: 100% 100%, cover, cover;
}

.certificate-container {
  width: 1100px;
  background-color: #ffffff;
}


.generateBTN {
  position: absolute;
}

.ml-1 {
  margin-left: 5px;
}

.pad-100px {
  padding: 50px;
}

.h-inherit {
  height: inherit;
}

.logo_style {
  height: 50px;
  margin: 50px 0;
}


.searchBoxContainer { 
  height: 350px;
  overflow-y: scroll;
}



.badge-primary {
  color: #666;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  border: 1px solid #aaa;
}


.StudentDetails li {
  list-style: none;
  padding: 5px 10px;
  border-bottom: 1px solid #F94A78;
  border-radius: 0px;
  /* border-radius: 5px; */
  margin-top: 5px;
}

.StudentDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.CompanyUsers .card {
  border-color: #F94A78;
}

.CompanyDetails {
  padding: 0;
}

.CompanyDetails li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}



.courseList .card {
  border-color: #F94A78;
}

.courseList {
  padding: 0;
}

.courseList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.courseList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.courseList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.companyUserList .card {
  border-color: #F94A78;
}

.companyUserList {
  padding: 0;
}

.companyUserList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.companyUserList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.companyUserList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.companyList .card {
  border-color: #F94A78;
}

.companyList {
  padding: 0;
}

.companyList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.companyList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.companyList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}

.w-80px {
  width: 80px;
}

.w-80pc {
  width: 80vh;
}

.h-90pc {
  height: 90vh;
}

.pm0 {
  padding: 0;
  margin: 0;
}

.searchBox {
  border-radius: 20px;
  overflow: hidden;
}

.searchBox input {
  border-radius: 20px;
  border: none;
}

.searchBox .input-group {
  padding: 0 10px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0);
  z-index: 999999;
  border: none;
}

.searchBox .input-group-text {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0);
  border: none;
}

.no-border {
  border: none;
}


.z999 {
  z-index: 999999;
  width: calc(100% - 270px)
}


/* Elearning */




.x-scroll {
  overflow-x: scroll;
}

.slot {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}