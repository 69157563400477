.truncate {
    display: inline-block;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate + a {
  display: inline-block;
    vertical-align: top;
    margin-top: -1px;
  }